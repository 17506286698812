import React, { useState, useEffect, useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import TaskList from './components/TaskList';
import TaskForm from './components/TaskForm';
import EmailForm from './components/EmailForm';
import './styles.css';

const App = () => {
  const [tasks, setTasks] = useState([]);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const ws = useRef(null);

  useEffect(() => {
    // Connect to the WebSocket server
    ws.current = new WebSocket('https://cptaskmanagerlive-66c474f9bb32.herokuapp.com/');

    ws.current.onopen = () => {
      console.log('WebSocket connection established');
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);

      switch (data.type) {
        case 'init':
          setTasks(data.tasks);
          break;
        case 'update':
          setTasks(data.tasks);
          break;
        default:
          break;
      }
    };

    ws.current.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const addTask = (task) => {
    const newTask = { ...task, id: Date.now().toString() };
    setTasks((prevTasks) => [...prevTasks, newTask]);
    ws.current.send(JSON.stringify({ type: 'add', task: newTask }));
  };

  const deleteTask = (id) => {
    setTasks((prevTasks) => prevTasks.filter(task => task.id !== id));
    ws.current.send(JSON.stringify({ type: 'delete', id }));
  };

  const editTask = (updatedTask) => {
    setTasks((prevTasks) => prevTasks.map(task => task.id === updatedTask.id ? updatedTask : task));
    ws.current.send(JSON.stringify({ type: 'edit', task: updatedTask }));
    setTaskToEdit(null);
  };

  const clearEdit = () => {
    setTaskToEdit(null);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedTasks = Array.from(tasks);
    const [reorderedTask] = updatedTasks.splice(result.source.index, 1);
    reorderedTask.priority = result.destination.droppableId;
    updatedTasks.splice(result.destination.index, 0, reorderedTask);

    setTasks(updatedTasks);
    ws.current.send(JSON.stringify({ type: 'reorder', tasks: updatedTasks }));
  };

  const priorities = ['Immediate', 'High', 'Normal'];

  return (
    <div className="app">
      <h1>Task Management</h1>
      <TaskForm
        addTask={addTask}
        editTask={editTask}
        taskToEdit={taskToEdit}
        clearEdit={clearEdit}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="task-lists">
          {priorities.map(priority => (
            <TaskList
              key={priority}
              priority={priority}
              tasks={tasks.filter(task => task.priority === priority)}
              onDelete={deleteTask}
              onEdit={setTaskToEdit}
            />
          ))}
        </div>
      </DragDropContext>
      <EmailForm tasks={tasks} />
    </div>
  );
};

export default App;
