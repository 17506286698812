import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const EmailForm = ({ tasks }) => {
  const [emails, setEmails] = useState('');

  const suggestedEmails = [
    'alexei.machkevitch@gmail.com',
    'coronaprojects@gmail.com',
  ];

  const addSuggestedEmail = (email) => {
    setEmails((prevEmails) => {
      const emailList = prevEmails.split(',').map(e => e.trim()).filter(e => e);
      if (!emailList.includes(email)) {
        return emailList.length > 0 ? `${prevEmails}, ${email}` : email;
      }
      return prevEmails;
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const taskSummary = tasks.reduce((summary, task) => {
      return summary + `${task.priority} Priority - ${task.name}: ${task.description}\n`;
    }, '');

    const templateParams = {
      to_email: emails,
      message: taskSummary,
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    ).then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Email sent successfully!');
      }, (error) => {
        console.log('FAILED...', error);
        alert('Failed to send email.');
      });

    setEmails('');
  };

  return (
    <div className="email-form-container">
      <form className="email-form" onSubmit={sendEmail}>
        <input
          type="text"
          placeholder="Enter comma-separated emails"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          required
        />
        <button type="submit">Send Summary</button>
      </form>
      <div className="suggested-emails">
        {suggestedEmails.map((email) => (
          <button key={email} onClick={() => addSuggestedEmail(email)}>
            {email}
          </button>
        ))}
      </div>
    </div>
  );
};

export default EmailForm;
